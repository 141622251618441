import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
// import Slider from "react-slick";

const Carousel = dynamic(() => import("../components/Carousel/Carousel"));
const Slider = dynamic(() => import("react-slick"));

// import Carousel from "../components/Carousel/Carousel";

import { client } from "../lib/Client";

import PostCard from "../components/PostCard/PostCard";

import TourCard from "../components/TourCard/TourCard";

import ContactFrom from "../components/ContactForm/ContactForm";

import Mapa from "../components/Map/Mapa";

import useCheckMobileScreen from "../lib/IsMobile";

import styles from "../styles/Home.module.scss";

import divider from "../public/divider.png";

import partner1 from "../public/partners/dom-house.jpg";
import partner2 from "../public/partners/house-of-bikes.jpg";
import partner3 from "../public/partners/kart-center.jpg";
import partner4 from "../public/partners/konsulat.jpg";
import partner5 from "../public/partners/pomorskie-apartamenty.jpg";
import partner6 from "../public/partners/prot.jpg";
import partner7 from "../public/partners/studio-57.png";
import partner8 from "../public/partners/visitgdansk.png";
import partner9 from "../public/partners/zorkout.jpg";
import Divider from "../components/Divider/Divider";

export default function Home({ tours, posts }) {
    const isMobile = useCheckMobileScreen();

    const [areChipsShown, showChips] = useState(false);

    const handleShowChips = () => {
        showChips(!areChipsShown);
    };

    useEffect(() => {
        if (isMobile) {
            showChips(false);
        } else {
            showChips(true);
        }
    }, [isMobile]);

    const categories = [
        { name: "All", value: "all" },
        { name: "Workshops", value: "workshops" },
        { name: "Active", value: "active" },
        { name: "Sightseeing", value: "sightseeing" },
        { name: "Culinary", value: "culinary" },
        { name: "Outside Gdańsk", value: "outsideGdansk" },
        { name: "City Games", value: "cityGames" },
    ];

    const [category, setCategory] = useState("all");
    const [active, setActive] = useState("all");

    // const [tourList, setTourList] = useState(tours);

    const tourList = Array.from(new Set(tours.map((a) => a._id))).map((_id) => {
        return tours.find((a) => a._id === _id);
    });

    const filtered = tourList.filter((tour) => {
        if (category !== "all") {
            return tour.categorySet.includes(category);
        } else {
            return tourList;
        }
    });

    const handleClick = (category) => {
        setCategory(category);
        setActive(category);
    };

    var settings = {
        dots: false,
        infinite: false,
        centerMode: false,
        arrows: filtered.length > 3 ? true : false,
        slidesToShow: !isMobile && filtered.length > 1 ? 4 : 1,
        slidesToScroll: !isMobile ? 1 : 1,
        initialSlide: 0,
        variableWidth: true,
        accessibility: true,
        lazyLoad: "progressive",
        // responsive: [
        //     {
        //         breakpoint: 900,
        //         settings: {
        //             slidesToScroll: 1,
        //         },
        //     },
        //     {
        //         breakpoint: 1024,
        //         settings: {
        //             slidesToScroll: 3,
        //         },
        //     },
        // ],
    };

    return (
        <div className={styles.container}>
            {/* <Head>
                <title>Poland by Locals - Tours in Gdańsk</title>
                <meta
                    name='description'
                    content='We believe that every trip should be a trip of your lifetime. When we travel, we enjoy meeting local people, visiting unique places and tasting regional cusine.'
                />
                <link rel='icon' href='/favicon.ico' />
            </Head> */}

            <main className={styles.main}>
                <section className={styles.intro}>
                    <h1>Tours and Events in Gdańsk</h1>
                </section>
                <section>
                    <div className={styles.catFilters}>
                        {isMobile ? (
                            <button
                                className={styles.showHide}
                                onClick={handleShowChips}
                            >
                                {areChipsShown ? "X" : "Show filters"}
                            </button>
                        ) : null}
                        {areChipsShown && (
                            <div className={styles.chips}>
                                <>
                                    {categories.map((cat, i) => (
                                        <button
                                            key={i}
                                            className={
                                                active === cat.value
                                                    ? styles.chipActive
                                                    : styles.chip
                                            }
                                            onClick={() =>
                                                handleClick(cat.value)
                                            }
                                        >
                                            {cat.name}
                                        </button>
                                    ))}
                                </>
                            </div>
                        )}
                    </div>
                    {/* <Link href='/tours' className={styles.allToursLink}>
                        Show all tours
                    </Link> */}
                    <div className={styles.toursShowcase}>
                        {filtered.length && (
                            <Slider {...settings}>
                                {filtered.map((tour, i) => (
                                    <TourCard key={i} data={tour} />
                                ))}
                            </Slider>
                        )}
                    </div>
                </section>
                <section>
                    <div className={styles.banner}>
                        <Image
                            className={styles.image}
                            src={divider}
                            alt=''
                            width={300}
                        />
                        <h2>
                            We belive that <span>every trip</span> should be{" "}
                            <span>a trip of your lifetime.</span>
                        </h2>
                    </div>
                </section>
                <section>
                    <div className={styles.blogHeader}>
                        {/* <Divider /> */}
                        <h2>Our blog</h2>
                        <p>
                            <Link href='/blog'>Read more posts</Link>
                        </p>
                    </div>
                    {posts.length && (
                        <div className={styles.postList}>
                            {posts.slice(0, 4).map((sPost) => (
                                <PostCard key={sPost._id} data={sPost} />
                            ))}
                        </div>
                    )}
                </section>
                <section>
                    <div className={styles.banner}>
                        <Image
                            className={styles.image}
                            src={divider}
                            alt=''
                            width={300}
                        />
                        <h2>Visiting Gdańsk? Need a guide?</h2>
                        <a href='/need-a-guide'>Click here to read more!</a>
                    </div>
                </section>
                <section>
                    <div>
                        <h2>Our partners</h2>
                    </div>
                    <div className={styles.partners}>
                        <Carousel slidesToShow={5}>
                            <Image
                                src={partner1}
                                height={200}
                                width={200}
                                alt='logo'
                            />
                            <Image
                                src={partner2}
                                height={200}
                                width={200}
                                alt='logo'
                            />
                            <Image
                                src={partner3}
                                height={200}
                                width={200}
                                alt='logo'
                            />
                            <Image
                                src={partner4}
                                height={200}
                                width={200}
                                alt='logo'
                            />
                            <Image
                                src={partner5}
                                height={200}
                                width={200}
                                alt='logo'
                            />
                            <Image
                                src={partner6}
                                height={200}
                                width={200}
                                alt='logo'
                            />
                            <Image
                                src={partner7}
                                height={200}
                                width={200}
                                alt='logo'
                            />
                            <Image
                                src={partner8}
                                height={200}
                                width={200}
                                alt='logo'
                            />
                            <Image
                                src={partner9}
                                height={200}
                                width={200}
                                alt='logo'
                            />
                        </Carousel>
                    </div>
                </section>
                <section>
                    <div>
                        <Divider />
                        <h2>Contact us</h2>
                    </div>
                    <div className={styles.rowX}>
                        <div className={styles.col50}>
                            <ContactFrom />
                        </div>
                        <div className={styles.col50}>
                            <Mapa isWide={false} />
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export async function getStaticProps() {
    const toursQuery = [
        `*[!(_id in path('drafts.**')) && _type == "tour" && isEvent == false && __i18n_lang == "en-us"] | order(orderRank asc){
            _id,
            title,
            subtitle,
            slug,
            categorySet,
            duration,
            groupTour,
            isEvent,
            location,
            numberOfPeopleCombos,
            poster,
            orderRank
        }`,
    ];

    const allPostsQuery = [
        `*[!(_id in path('drafts.**')) && _type == "post" && __i18n_lang == "en-us" ] | order(_createdAt desc) {
            _id,
            title,
            subtitle,
            slug,
            poster,
            _createdAt
        }`,
    ];

    const posts = await client.fetch(allPostsQuery);

    const tours = await client.fetch(toursQuery);

    return {
        props: {
            tours,
            posts,
        },
    };
}
